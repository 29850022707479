import React from 'react'
import { Stack, Hero, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Seo from '@widgets/Seo'
import HeroComponent from '../components/Hero/Hero'

const Posts = ({data,
  data: { posts, featuredPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {}, comparePosts, reviewsPosts } = {} } = props

  return (
    <>
      <Seo title='Home' />
      <Hero>
        <HeroComponent {...props} />
      </Hero>
      <Divider space={5} />
      <Stack>
        <Main>
        <CardList
            nodes={comparePosts.nodes}
            limit={5}
            variant='vertical-cover'
            title='Compare brokers from all around the world.'
            slider
            columns={[1, 2, 3, 3]}
            autoPlay
          />
          <br/>
          <CardList
            nodes={comparePosts.nodes.slice(5,10)}
            limit={5}
            columns={[1, 2, 3, 3]}
                    variant={['horizontal-md', 'horizontal-aside']}
                    omitMedia
            columns={[1, 2, 3, 3]}
            autoPlay
          />
        </Main>
      </Stack>
      <Divider space={5} />
      <Stack>
        <Main>
        <CardList
            nodes={reviewsPosts.nodes}
            limit={5}
            variant='vertical-cover'
            title='Reviews from around the web of every broker ever.'
            slider
            columns={[1, 2, 3, 3]}
            autoPlay
          />
          <br/>
          <CardList
            nodes={reviewsPosts.nodes.slice(5,10)}
            limit={5}
            columns={[1, 2, 3, 3]}
                    variant={['horizontal-md', 'horizontal-aside']}
                    omitMedia
            columns={[1, 2, 3, 3]}
            autoPlay
          />
        </Main>
      </Stack>
      {/* <Divider space={5} />
      {posts.group.length &&
        posts.group.map((group, index) => (
          <React.Fragment key={`${group.categoryName}.list`}>
            {index % 2 === 0 ? (
              <Stack
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
              >
                <Main>
                  <CardList
                    nodes={group.nodes}
                    limit={3}
                    columns={[1, 1, 1, 3]}
                    variant={[
                      'horizontal-md',
                      'horizontal',
                      'horizontal',
                      'vertical',
                    ]}
                  />
                  <Divider space={2} />
                  <CardList
                    nodes={group.nodes}
                    limit={3}
                    skip={3}
                    columns={[1, 2, 3, 3]}
                    variant={['horizontal-md', 'horizontal-aside']}
                    omitMedia
                  />
                </Main>
              </Stack>
            ) : (
              <Stack
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
                direction={['column', 'column', 'column', 'row']}
              >
                <Sidebar
                  sx={{
                    pl: 0,
                    pr: [0, 0, 0, 3],
                    display: [null, `flex`],
                    flexDirection: [`column`, `column`, `column`, `row`],
                  }}
                >
                  <CardList
                    nodes={group.nodes}
                    limit={1}
                    columns={[1]}
                    variant={[
                      'horizontal-md',
                      'horizontal',
                      'horizontal',
                      'vertical',
                    ]}
                    omitCategory
                  />
                </Sidebar>
                <Main
                  sx={{
                    display: [null, `flex`],
                    flexDirection: [`column`, `column`, `column`, `row`],
                  }}
                >
                  <Divider space={2} />
                  <CardList
                    nodes={group.nodes}
                    limit={3}
                    skip={1}
                    columns={[1, 1, 3, 1]}
                    variant={[
                      'horizontal-md',
                      'horizontal-md',
                      'horizontal-aside',
                    ]}
                    mediaType='icon'
                    omitCategory
                  />
                  <Divider space={2} />
                </Main>
                <Sidebar
                  sx={{
                    pl: [0, 0, 0, 3],
                    display: [null, `flex`],
                    flexDirection: [`column`, `column`, `column`, `row`],
                  }}
                >
                  <CardList
                    nodes={group.nodes}
                    limit={1}
                    skip={4}
                    columns={[1]}
                    variant={[
                      'horizontal-md',
                      'horizontal',
                      'horizontal',
                      'vertical',
                    ]}
                    omitCategory
                  />
                </Sidebar>
              </Stack>
            )}
            {index !== posts.group.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      
      
      <Divider space={5} /> */}
      <Stack>
        <Main sx={{ zIndex: 1 }}>
          {services.mailchimp && <NewsletterExpanded simple />}
        </Main>
      </Stack>
    </>
  )
}

export default Posts
